<template>
  <div class="login-container">
    
    <!-- -->
      <div class="container-fluid bg-tarimba" id="topo-login">
        <div class="row">
          <div class="col-sm-12">
              <img  src="/img/logo_fonte_branca.png" class="img-fluid mx-auto d-none d-sm-block logo-tarimba-home" alt="Logo Tarimba" />
              <img  src="/img/logo_fonte_branca.png" class="img-fluid mx-auto d-block d-sm-none logo-tarimba-home " style="margin-top:230px;" alt="Logo Tarimba" />
              <h1  class="text-center txt-combina">Combina seus ingredientes com criatividade</h1>
            <br> 

            <form id="form-login-page" class="col-md-2 col-lg-4 offset-md-5 offset-lg-4" name="login" >
                  <div class="form-group">
                    <label style="color:#FFF;font-weight:bold;">Informe seu E-mail </label>
                    <input type="text" class="form-control" v-model="email" name="email" placeholder="E-mail" aria-describedby="email" required>
                    <small class="form-text text-muted"></small>
                  </div>
                  <div class="form-group">
                     <label style="color:#FFF;font-weight:bold;">Senha</label>
                    <input type="password" class="form-control" v-model="senha" name="senha" placeholder="Senha" aria-describedby="senha" required>
                    <small  class="form-text text-muted"></small>
                  </div>

                  <small><i><router-link to="/resgate" style="font-size:0.85em;color:#FFF;" class=" text-center">Esqueceu sua senha?</router-link></i></small>
                  <br><br>
                  
                 <button v-if="showLoading == false" style="width:100%;background:#FFF;color:#000;border:none !important;" @click.prevent="logar" type="button " class="btn  btn-primary bold">ENTRAR</button>
                 <small class="text-center d-block" v-if="erro != ''" style="color:red;margin-top:10px;">{{erro}}</small>
                </form>
                
                  <div v-if="showLoading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
<br><br>


                <h2 class="text-center" style="color:#F18800;"><strong>Ainda não é um Tarimbado?</strong> </h2>
                <router-link class="d-block mx-auto" style="background-color:white;font-weight:bold;font-size:1.3em;padding:15px; 20px;color:#000;max-width:400px;border-radius:10px;" to="/cadastro">CLIQUE AQUI E FAÇA SEU CADASTRO</router-link>
                <br><br>
              
          </div>
        </div>
      </div>
       




  </div>
</template>

<style>
.bg-tarimba{background:url('/img/background.png');background-size: cover;background-repeat: no-repeat;}
.logo-tarimba-home{margin-top:20%;}
.txt-combina{color:#FFF;font-size:2.3em;}


@media all and (max-width:768px){
  .bg-tarimba{background:url('/img/bg-mobile.jpg') !important;background-size: cover !important;background-repeat: no-repeat !important;}
  .txt-combina{font-size:1.2em !important;}
  .txt-experimente,.txt-comecar,h1{font-size:2em !important;}
  .h2-mobile{font-size:1.4em !important;}
  .bg-roxo{background-image:none;}
}
</style>

<script>
import servidor from '@/servidor'


export default {
  name: 'Login',
  metaInfo: {
    title: 'Login - Tarimba na cozinha',
    meta:[{name: 'description', 
    content: "Faça seu login ou cadastre-se para visualizar nossas receitas. Tarimba Na Cozinha. Combina seus ingredientes com criatividade. ‍"},
    { property: 'og:image', content: 'https://tarimbanacozinha.com.br/img/logo_tarimba.jpg' },
      { property: 'og:url', content: 'https://tarimbanacozinha.com.br/login' },
         { property: 'og:type', content: 'website' },
            { property: 'og:image:type', content: 'image/jpeg' },
       { property: 'og:title', content: 'Tarimba na cozinha - Receitas' },
       { property: 'og:description', content: 'Encontre receitas com os ingredientes que você tem na sua geladeira e na sua despensa.' }
    ]
  },
  components: {},
  data(){
    return {
      showLoading:false,
      email:'',
      senha:'',
      erro:''
    }
  },
methods:{
   setCookie(cname, cvalue, exdays) {
     
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    deleteCookie(){
      document.cookie = "usuario=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
   logar(){
     
      this.showLoading = true;
      this.erro = 'Aguarde...';
      let dados = new FormData();
      
      dados.append('email',this.email);
      dados.append('senha',this.senha);

      fetch(servidor+'verificacao/logar',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.erro = data.erro;
            }else{
              this.$store.commit('logarUsuario',data);
              this.setCookie('usuario',JSON.stringify(this.logado), this.logado.dias);
              this.$router.push('/receitas');
            }
          });
    }
  },
  computed:{
    logado(){
      return this.$store.state.usuario;
    }
  },
  mounted(){
 this.$scrollTo("#topo-login");
  
  }
}
</script>
